<template>
  <div class="after-sale">
      <!-- <h2>{{this.$route.query.owid}}</h2> -->
      <div class="w title_new">
        <h2>{{newList.wzbt}}</h2>
        <div class="time">{{newList.fbsj}}</div>
        <div class="content_new" v-html="newList.wznr">{{newList.wznr}}</div>
      </div>
  </div>
</template>

<script>
import { getOneNews } from './model'
export default {
  data(){
    return {
      owid:{owid:''},
      newList:''
    }
  },
    created(){
      this.owid.owid = this.$route.query.owid
      getOneNews(this.owid).then(res => {
       this.newList = res;
      })
    }
}
</script>

